@import '@style/theme.scss';
@import '@style/media.scss';

.link {
    @include font-subscript;
    font-size: 12px;
    font-weight: 500;
    font-family: $font-secondary;
    color: $fg-secondary;
    text-decoration: none;
    display: block;
    width: fit-content;
    padding-bottom: 8px;

    & svg * {
        fill: $fg-secondary;
    }
}

.footer-items {
    &>* {
        padding-top: 10px;
        padding-right: 15px;
    }
}